/**
 * Created by stapletont on 28/01/2015.
 */

// Module to control the topics of events fired (Publish/Subscribe)

define('controlTower', function() {


  /**
   * Methods for publish and subscribe
   */
  var controlTower = {
    /**
     * Public method to get topics and messages
     * @returns {object}
     */
    topics: {},
    messages: {},

    /**
     * Subscribe calls for modules
     * @param topic
     * @param [callback]
     * @returns {{remove: Function} | {messages} | undefined}
     */
    subscribe: function(topic, callback) {
      var topics = controlTower.topics;
      var messages = controlTower.messages;

      if (typeof callback !== 'function') {
        // eslint-disable-next-line no-console
        console.error('Error: Invalid Parameter', 'Callback is not a function',
          'subscribe');
        return;
      }

      if (!topics.hasOwnProperty(topic)) {
        topics[topic] = [];
        messages[topic] = [];
      }

      var index = topics[topic].push(callback) - 1;
      var returnObject = {
        remove: function() {
          delete topics[topic][index];
        },
        messages: messages[topic]
      };

      return returnObject;
    },

    /**
     * Call a subscription and method from a module
     * @param topic
     * @param [info]
     * @param store
     * @returns {boolean}
     */
    publish: function(topic, info, store) {
      var topics = controlTower.topics;
      var messages = controlTower.messages;
      var args = arguments;

      if (topic === '') {
        return false;
      }

      if (!topics.hasOwnProperty(topic)) {
        topics[topic] = [];
        messages[topic] = [];
      }

      if (store) {
        messages[topic].push(info);
      }

      for (var i = 0; i < topics[topic].length; i++) {
        var f = topics[topic][i];
        if (f) {
          f.apply(this, Array.prototype.slice.call(args, 1));
        }
      }
      return true;
    },

    clear: function(topic) {
      let topics = controlTower.topics;
      let messages = controlTower.messages;
      delete  messages[topic];
      delete topics[topic];
      messages[topic] = [];
      topics[topic] = [];
    }
  };

  return controlTower;
});
